<template>
  <div class="parent mt-5 py-5">
    <div class="container ">
      <div class="row">
        <div class="col-md-4 mb-2 mt-md-n5">
          <router-link to="/subsidiaries/Pamodzi Unique Engineering">
            <div class="">
              <div class="sub-item">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/unique.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div>
                    <span class="gi-01"></span>Pamodzi Unique Engineering
                  </div>
                  <div class="gi-01">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-2 mt-md-0">
          <router-link to="/subsidiaries/Pamodzi Smart">
            <div class="">
              <div class="sub-item mt-md-0">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/smart.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-02"></span>Pamodzi Smart Store</div>
                  <div class="gi-02">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-2 mt-md-5">
          <router-link to="/subsidiaries/Pamodzi Petroleum">
            <div class="">
              <div class="sub-item mt-md-0">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/petroleum.jpg"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-03"></span>Pamodzi Petroleum</div>
                  <div class="gi-03">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mb-2 mt-md-n5">
          <router-link to="/subsidiaries/Pamodzi Pannonia">
            <div class="">
              <div class="sub-item">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/pannonia.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-04"></span>Pamodzi Pannonia</div>
                  <div class="gi-04">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-2 mt-md-0">
          <router-link to="/subsidiaries/Pamodzi rail">
            <div class="">
              <div class="sub-item mt-md-0">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/rail.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-11"></span>Pamodzi rail</div>
                  <div class="gi-11">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-2 mt-md-5">
          <router-link to="/subsidiaries/Phephani Tyre Tech">
            <div class="">
              <div class="sub-item mt-md-0">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/phephani.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-12"></span> Phephani Tyre Tech</div>
                  <div class="gi-12">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row ">
        <div class="col-md-4 mb-2 mt-md-n5">
          <router-link to="/subsidiaries/Pamodzi Technologies">
            <div class="">
              <div class="sub-item">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/tech.jpg"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-07"></span>Pamodzi Technology</div>
                  <div class="gi-07"></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-2 mt-md-0">
          <router-link to="/subsidiaries/Pamodzi Green Energy">
            <div class="">
              <div class="sub-item mt-md-0">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/greenEnergy.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-08"></span>Pamodzi Green Energy</div>
                  <div class="gi-08">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-2 mt-md-5">
          <router-link to="/subsidiaries/Pamodzi Resources">
            <div class="">
              <div class="sub-item mt-md-0">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/resources.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div><span class="gi-09"></span>Pamodzi Resources</div>
                  <div class="gi-09">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mb-2 mt-md-n5">
          <router-link to="/subsidiaries/Pamodzi FAW + Fleet Management">
            <div class="">
              <div class="sub-item">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/fawLogo.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div>
                    <span class="gi-10"></span>Pamodzi FAW/ Fleet
                    Management
                  </div>
                  <div class="gi-10">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div> 
        <div class="col-md-4 mb-2 mt-md-0">
          <router-link to="/subsidiaries/PRES">
            <div class="">
              <div class="sub-item">
                <div class="img">
                  <img
                    src="../../assets/images/subsidiaries/pamodziGroupLogos/pres.png"
                    alt=""
                    class="img-fluid w-100"
                  />
                </div>
                <div class="caption d-flex">
                  <div>
                    <span class="gi-02"></span>Pamodzi Resources and Energy Services
                  </div>
                  <div class="gi-02">></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubsidiariesGrid",
  methods: {
    selectSub(id) {
      //   selectedSubCat = allSubs.filter((x)=> x.id == id)[0];
    },
  },
  data() {
    return {
      selectedSubCat: {},
    };
  },
};
</script>

<style scoped lang="scss">
.caption {
  min-height: 50px;
  background-color: #212120;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  //   width: 90%;
  div:first-child {
    width: 85%;
    font: normal normal bold 15px/20px Open Sans;
  }

  div:last-child {
    width: 15%;
  }

  div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  span {
    position: absolute;
    min-width: 20%;
    min-height: 5px;
    left: 0;
    top: 0;
  }
}

.parent {
  background-image: url("../../assets/images/subsidiaries/Group-319.svg");
  background-position: 10px 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.gi-01 {
  background-color: #bbd549;
}

.gi-02 {
  background-color: #d0d0d0;
}

.gi-03 {
  background-color: #9e248e;
}

.gi-04 {
  background-color: #ed1c24;
}

.gi-05 {
  background-color: #febe10;
}

.gi-06 {
  background-color: #f57b20;
}

.gi-07 {
  background-color: #ec008c;
}

.gi-08 {
  background-color: #0074be;
}

.gi-09 {
  background-color: #09bef2;
}

.gi-10 {
  background-color: #9b1d00;
}

.gi-11 {
  background-color: #dbd0c0;
}

.gi-12 {
  background-color: #a08864;
}
</style>
