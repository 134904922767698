<template>
  <div class="">
    <SubsidiariesATF selected-id="subsidiaries"></SubsidiariesATF>
    <SubsidiariesInfo
      v-if="notMain"
      :selectedId="selectedID"
    ></SubsidiariesInfo>
    <SubsidiariesGrid v-if="!notMain"></SubsidiariesGrid>
    <SubsidiariesMore v-if="notMain"></SubsidiariesMore>
  </div>
</template>

<script>
import SubsidiariesGrid from "@/components/subsidiaries/SubsidiariesGrid.vue";
import SubsidiariesATF from "../components/subsidiaries/SubsidiariesATF.vue";
import SubsidiariesMore from "../components/subsidiaries/SubsidiariesMore.vue";
import SubsidiariesInfo from "../components/subsidiaries/SubsidiariesInfo.vue";

export default {
  name: "News",
  components: {
    SubsidiariesGrid,
    SubsidiariesATF,
    SubsidiariesMore,
    SubsidiariesInfo,
  },
  data() {
    return {
      notMain: false,
      selectedID: "",
    };
  },
  watch: {
    "$route.params": {
      handler: function(params) {
        if (this.$route.name === "subsidiaries") {
          this.id = params.id;
          if (params.id) {
            this.notMain = params.id !== "subsidiaries";
          } else {
            this.notMain = false;
          }
        }
      },
      deep: true,
    },
  },
  beforeMount() {
  },
  created() {
    document.title = "Pamodzi | Subsidiaries"
  }
};
</script>

<style scoped></style>
