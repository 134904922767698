<template>
  <div class="">
    <NavATF route-id="team"></NavATF>
    <!-- <TeamExecutives></TeamExecutives> -->
    <TestParralax></TestParralax>
  </div>

</template>

<script>
import NavATF from "@/components/shared/NavATF";
import TeamExecutives from "@/components/team/TeamExecutives";
import TestParralax from '../components/shared/TestParralax.vue';

export default {
  name: "Team",
  components: { NavATF, TestParralax},
  created() {
    
    TestParralax.title = "Pamodzi | Team"
  }
}
</script>

<style scoped>

</style>
