<template>
  <div class="">
    <div v-if="comingSoon" class="">
      <ComingSoon></ComingSoon>
    </div>
    <div v-else class="">
      <NavATF :route-id="item.routeID"></NavATF>
      <TeamMemberAbout :route-id="item.routeID" class="pt-5"></TeamMemberAbout>
      <TeamMemberEducation
        :route-id="item.routeID"
        class="pt-md-5 pt-3"
      ></TeamMemberEducation>
      <TeamMemberAchievements
        :route-id="item.routeID"
        class="pt-md-5 pt-3"
      ></TeamMemberAchievements>
      <TeamMemberAwards
        :route-id="item.routeID"
        class="pt-md-5 pt-3"
      ></TeamMemberAwards>
    </div>
  </div>
</template>

<script>
import NavATF from "@/components/shared/NavATF";
import ComingSoon from "@/components/shared/ComingSoon";
import TeamMemberEducation from "@/components/team/teamMember/TeamMemberEducation";
import TeamMemberAchievements from "@/components/team/teamMember/TeamMemberAchievements";
import TeamMemberAbout from "@/components/team/teamMember/TeamMemberAbout";
import TeamMemberAwards from "@/components/team/teamMember/TeamMemberAwards";
import teamData from "../assets/info/teams.json";

export default {
  name: "TeamMemberDeatils",
  components: {
    TeamMemberAwards,
    TeamMemberAbout,
    TeamMemberAchievements,
    TeamMemberEducation,
    ComingSoon,
    NavATF,
  },
  beforeMount() {
    this.item.routeID = this.$route.params.id;

    document.title = "Pamodzi | Team | " + this.item.routeID;
  },
  data() {
    return {
      item: {
        routeID: "",
        teamData,
        comingSoon: false,
      },
    };
  },
};
</script>

<style scoped></style>
