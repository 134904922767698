<template>
  <div class="container-fluid background pt-5">
  <div class="container ">
    <div class="row text-center">
      <!-- <h2 class="font-weight-bold">Newly Designed Cards</h2> -->
    </div>
    <div class="row">
      <div
        class="col-md-4 px-md-2"
        v-for="member in teamData"
        v-bind:key="member"
      >
        <router-link :to="{ path: '/team/' + member.id }">
          <div class="shadow team-card">
            <div class="team-card-image">
              <img  v-lazy="require('../../assets/images/team/teamCard/'+ member.picture)" :alt="member.name" />
              <div class="team-card-overlay">
                <div class="overlay-content">
                  <h3 class="team-card-name">{{ member.name }}</h3>
                  <button class="view-more-button">View More</button>
                </div>
              </div>
            </div>
            <div class="team-card-details pamodzi-black-backgropund">
              <div class="red-line"></div>
              <h4 class="team-card-name">{{ member.name }}</h4>
              <p class="team-card-description">{{ member.role }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>
  
  <script>
import teamData from "../../assets/info/teams.json";

export default {
  data() {
    return {
      teamData,
    };
  },
  
};
</script>
  
<style scoped>
.team-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.team-card-image {
  position: relative;
  overflow: hidden;
  height: 250px;
}

.team-card-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.team-card:hover .team-card-image img {
  transform: scale(1.1);
}

.team-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-card:hover .team-card-overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
  color: #fff;
  padding: 20px;
}

.team-card-details {
  color: #f8f8f8;
  padding: 20px;
  position: relative;
}

.red-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 5px;
  background-color: rgb(155, 29, 0);
}

.team-card-name {
  font-size: 24px;
  margin-bottom: 5px;
}

.team-card-description {
  font-size: 18px;
  color: #888;
  margin-bottom: 0;
}

.view-more-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-more-button:hover {
  background-color: #f0f0f0;
}
a:hover {
  text-decoration: none;
}

.background {
  background-image: url("../../assets/images/subsidiaries/Group-319.svg");
  background-position: 10px 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}

</style>
  